// @flow

import * as React from 'react';
import classNames from 'classnames';
import ynetLogoSrc from 'assets/client/images/ynet-logo.svg';
import { ynetLink } from 'common/constants/links';
import AccessibilityIcon from 'client/components/common/AccessibilityIcon';
// import Socials from '../Socials';

import BurgerMenu from './BurgerMenu';
import * as css from './Header.scss';

type Props = {
	className?: string,
	data?: any,
	disableLogo?: boolean,
	fixed?: boolean,
	isLogoShow?: boolean,
	isMobile: boolean,
};

const Header = ({ data, className, disableLogo, fixed, isLogoShow, isMobile }: Props) => {
	return (
		<div className={classNames(css.header, className, fixed && css.fixed, isLogoShow && css.showLogo)} id="header">
			<div className={css.headerWrapper}>
				<a href={ynetLink} className={css.ynetLogo} rel="noopener noreferrer" target="_blank">
					<img src={ynetLogoSrc} alt="ynet-logo" />
				</a>
				<div className={css.burgerBlock}>
					<AccessibilityIcon />
					<BurgerMenu />
				</div>
				{/* <Socials minified={isMobile} /> */}
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	data: {},
	disableLogo: false,
	fixed: false,
	isLogoShow: true,
};

export { Header };
