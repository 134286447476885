// @flow

/* eslint-disable prefer-template, max-len */
// import _ from 'lodash';

import { APP_CONFIG, CAN_USE_DOM, SHARE_CONSTANTS } from 'common/constants';
import { Responsive } from 'client/components/common/Responsive';

// eslint-disable-next-line import/prefer-default-export
export const share = (
	type: string,
	options?: { url: string, itemTitle: string, itemDescription: string, itemImage: string }
) => {
	if (!CAN_USE_DOM) {
		return;
	}

	// console.info('url:', url);
	// console.info('title:', itemTitle);
	// console.info('description:', itemDescription);

	// const utmHostname = Responsive.isMatching(Responsive.DESKTOP) ? 'ynet.co.il' : 'm.ynet.co.il';
	// const utms = `?utm_source=${utmHostname}&utm_medium=social&utm_campaign=${type}&utm_term=wellness_menta`;

	const domain = `${APP_CONFIG.domains.client.hostname}`;
	const urlProp = CAN_USE_DOM ? document.querySelector('meta[property="og:url"]') : null;
	let url: ?string = urlProp ? urlProp.getAttribute('content') : domain;
	const titleProp = CAN_USE_DOM ? document.querySelector('meta[property="og:title"]') : null;
	let title: ?string = titleProp ? titleProp.getAttribute('content') : '';
	const descriptionProp = CAN_USE_DOM ? document.querySelector('meta[property="og:description"]') : null;
	let description: ?string = descriptionProp ? descriptionProp.getAttribute('content') : '';
	// const imageProp = CAN_USE_DOM ? document.querySelector('meta[property="og:image"]') : null;
	// let image: ?string = imageProp ? imageProp.getAttribute('content') : '';
	const encode = encodeURIComponent;
	const text = title && description ? `${title}\n${description}` : '';
	// let url = domain;

	if (options?.url) {
		url = `${domain}/${options?.url}`;
	}

	if (options?.itemTitle) {
		title = options?.itemTitle;
	}

	if (options?.itemDescription) {
		description = options?.itemDescription;
	}

	// if (options?.itemImage) {
	// 	image = options?.itemImage;
	// }

	// console.info('type', type);
	// console.info('title:', title);
	// console.info('description:', description);
	// console.info('image:', image);
	// console.info('URL:', url);

	if (type === SHARE_CONSTANTS.FACEBOOK && url) {
		const fbURL = 'https://www.facebook.com/sharer.php?u=';

		window.open(`${fbURL}${encode(url)}`, 'sharer', 'width=555,height=600');
	}

	if (type === SHARE_CONSTANTS.WHATSAPP) {
		if (title) {
			if (Responsive.isMatching(Responsive.DESKTOP)) {
				window.open(
					`https://web.whatsapp.com/send?text=${encode(`${title}\n${description}\n${url}`)}`,
					'_blank'
				);
			} else {
				window.location.href = `https://wa.me/?text=${encode(`${title}\n${description}\n${url}`)}`;
				// window.location.href = 'whatsapp://send?text=' + url;
				// 'whatsapp://send?text=' + encode(title + '\n' + splitedDescription + '\n' + domain);
			}
		}
	}

	if (type === SHARE_CONSTANTS.TWITTER) {
		const twURL = 'https://twitter.com/intent/tweet?url=';
		const finalURL = `${twURL}${domain}&text=${encode(`${text}\n`)}`;

		const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
		shareWindow.opener = null;
	}
};
