/* eslint-disable max-len */
import _ from 'lodash';
// import { APP_CONFIG } from 'common/constants';

// const PROJECT_ID = 'woman-magazine-yit';

export const fileLinkIMGIX = (data, params) => {
	if (!data) {
		return null;
	}

	return data.selected === 'file' ? formatLink(_.get(data, 'file.url', ''), params) : _.get(data, 'remoteURL', '');
};

export const formatLink = (path, faceIndex = '0', params) => {
	// const CDN = `${APP_CONFIG.cdn.hostname}`;

	const defaultParams = `?auto=compress&auto=format&w=250&h=350&&fit=facearea&facepad=10&faces=1&faceindex=${faceIndex}`;
	let link;
	if (path !== '') {
		link = `https://memorialcandles.imgix.net/${path}${params ?? defaultParams}`;
	} else {
		link = 'https://memorialday2022.ynet.co.il/assets/client/share1200x600.jpeg';
	}

	// const REGULAR_URL = `https://storage.googleapis.com/${PROJECT_ID}.appspot.com/`;

	// if (!CDN) {
	// 	return `${REGULAR_URL}${path}`;
	// }

	return link;
};
